import React, { Component } from "react"

/* Constants */
import { env } from "../constants"

/* Components */
import { Picture } from "@alicorn/poker-ui"

/* Fields */
const DEALER_PLACES = ['10', '8', '6', '4', '2']

/* Widget RMP Dealer Cards */
class DealerCards extends Component {

    /* Filter Cards By Unqiue ID */
    getUniqueValues = (array, prop) => {
        return array.filter((element, index, self) =>
            index === self.findIndex(item =>
                item[prop] === element[prop]
            )
        )
    }

    /* Card status */
    status = card => {

        const { game } = this.props

        let status = false

        if (game && game.data) {
            const index = game.data.findIndex(e => parseInt(e.id) === parseInt(card.id))
            if (index > -1) {
                if (game.data[index].status) {
                    status = true
                }
            }
        }

        return status
    }

    /* Draw card image */
    _image = (card) => {

        const { game } = this.props
        const status = this.status(card)
        const style = status ? 'ump--animated-card-image' : ''

        if (game !== null) {
            return <Picture className={style} src={`${env.mediapoint}/cards/${card.image}`} alt="Dealer Card" />
        }

        return <Picture src={`${env.mediapoint}/cards/closed.webp`} alt="Dealer Card" />
    }


    /* Draw flip animation box */
    _flip = (card, index) => {

        const { game } = this.props

        const isActive = game !== null ? 'active' : ''

        return (
            <div className={`ump--dealer-flip-card ${isActive}`}>
                <div className="ump--dealer-flip-card-inner">
                    <div className="ump--dealer-flip-card-front">
                        <Picture src={`${env.mediapoint}/cards/closed.webp`} alt="Dealer Card" />
                    </div>
                    <div className="ump--dealer-flip-card-back">
                        {this._image(card, index)}
                    </div>
                </div>
            </div>
        )
    }

    /* Draw Status */
    _status = card => {

        const status = this.status(card)

        let style = ''
        if (card.dealer) {
            style += 'dealer-card'
        }

        return (
            <div className={`ump--dealer-card-status ${status ? 'ump--dealer-card-status-animation' : ''}`}>
                {status && <div className={`ump--dealer-card-status-corner ${style}`} />}
            </div>
        )

    }

    /* Draw Mini Status */
    _miniStatus = (card, index) => {

        const status = this.status(card)
        const { game, removedCard } = this.props

        if (game && removedCard && index === 0) {
            return (
                <div className={`ump--dealer-mini-status ${status ? 'ump--dealer-card-status-animation' : ''}`}>
                    <Picture src={`${env.mediapoint}/images/exchange.png`} alt="Exchange" />
                </div>
            )
        }
    }

    /* Draw card */
    _card = () => {

        const { cards } = this.props
        const dealer = this.getUniqueValues(cards, 'id')

        return (
            <div className="ump--dealer-box">

                <div className="ump--dealer-cards">
                    {dealer.map((card, index) => (
                        <div className={`ump--dealer-card`} key={`${card.id}`}>
                            {this._flip(card, index)}
                            {this._status(card, index)}
                            {this._miniStatus(card, index)}
                        </div>
                    ))}
                </div>

                <div className="ump--dealer-cards-places">
                    {DEALER_PLACES.map(item => <div key={`place-${item}`} className={`ump--dealer-card-place`}></div>)}
                </div>

            </div>
        )
    }


    /* Draw dealer game result */
    _result = () => {

        const { game } = this.props

        if (game) {
            if (parseInt(game.level) === 0) {
                return <div className={`ump--dealer-result ump--dealer-result-red`}>Нет игры</div>
            }
            if (parseInt(game.level) > 0) {
                return <div className={`ump--dealer-result`}>{game.name}</div>
            }
        }

    }


    render = () => {

        const { showDealerCards, style } = this.props


        const cardsStyle = showDealerCards ? "" : "hidden"

        return (
            <div className={`ump--dealer ${cardsStyle} ${style}`}>
                <h2>Рука дилера</h2>
                {this._card()}
                {this._result()}
            </div>
        )

    }

}

export default DealerCards