import React, { Component } from "react"

/* Constants */
import { env, utils } from "../constants"

/* Components */
import { Picture } from "@alicorn/poker-ui"


class GamePayout extends Component {

    minmax = () => {
        const { max, min, currency } = this.props
        return <span className="minmax">&nbsp;&nbsp; {utils.convertor(min, currency)} - {utils.convertor(max, currency)}</span>
    }

    render = () => {
        return (
            <>

                <div className="game-payout-box">
                    <div className="game-payout-row head">
                        <p className="game-payout-row-title" />
                        <p>ANTE</p>
                        <p>Играть</p>
                    </div>

                    <div className="game-payout-row">
                        <p className="game-payout-row-title">Рука дилера проходит квалификацию (пара или выше), вы выигрываете</p>
                        <p>Выигрыш 1:1</p>
                        <p>Выигрыш 1:1</p>
                    </div>

                    <div className="game-payout-row">
                        <p className="game-payout-row-title">Рука дилера не квалифицирована</p>
                        <p>Ничья</p>
                        <p>Выигрыш 1:1</p>
                    </div>
                </div>

                <div className="row">
                    <p>BLIND {this.minmax()}</p>
                    <p>Выплата</p>
                </div>
                <div className="combinations">
                    <div className="item">
                        <p>Тройка</p>
                        <div>
                            <Picture src={`${env.mediapoint}/images/combinations/threeofakind.svg`} alt="twopairs" />
                        </div>
                        <p className="result">СТЕЙ</p>
                    </div>
                    <div className="item">
                        <p>Стрит</p>
                        <div>
                            <Picture src={`${env.mediapoint}/images/combinations/street.svg`} alt="twopairs" />
                        </div>
                        <p className="result">1:1</p>
                    </div>
                    <div className="item">
                        <p>Флеш</p>
                        <div>
                            <Picture src={`${env.mediapoint}/images/combinations/flash.svg`} alt="twopairs" />
                        </div>
                        <p className="result">3:2</p>
                    </div>
                    <div className="item">
                        <p>Фулл хаус</p>
                        <div>
                            <Picture src={`${env.mediapoint}/images/combinations/fullhouse.svg`} alt="twopairs" />
                        </div>
                        <p className="result">3:1</p>
                    </div>
                    <div className="item">
                        <p>Каре</p>
                        <div>
                            <Picture src={`${env.mediapoint}/images/combinations/fourofakind.svg`} alt="twopairs" />
                        </div>
                        <p className="result">10:1</p>
                    </div>
                    <div className="item">
                        <p>Стрит флеш</p>
                        <div>
                            <Picture src={`${env.mediapoint}/images/combinations/streetFlash.svg`} alt="twopairs" />
                        </div>
                        <p className="result">50:1</p>
                    </div>
                    <div className="item">
                        <p>Рояль флеш</p>
                        <div>
                            <Picture src={`${env.mediapoint}/images/combinations/royalFlash.svg`} alt="twopairs" />
                        </div>
                        <p className="result">400:1</p>
                    </div>
                </div>


                <div className="row">
                    <p>TRIPS {this.minmax()}</p>
                    <p>Выплата</p>
                </div>

                <div className="combinations">
                    <div className="item">
                        <p>Тройка</p>
                        <div>
                            <Picture src={`${env.mediapoint}/images/combinations/threeofakind.svg`} alt="twopairs" />
                        </div>
                        <p className="result">3:1</p>
                    </div>
                    <div className="item">
                        <p>Стрит</p>
                        <div>
                            <Picture src={`${env.mediapoint}/images/combinations/street.svg`} alt="twopairs" />
                        </div>
                        <p className="result">4:1</p>
                    </div>
                    <div className="item">
                        <p>Флеш</p>
                        <div>
                            <Picture src={`${env.mediapoint}/images/combinations/flash.svg`} alt="twopairs" />
                        </div>
                        <p className="result">5:1</p>
                    </div>
                    <div className="item">
                        <p>Фулл хаус</p>
                        <div>
                            <Picture src={`${env.mediapoint}/images/combinations/fullhouse.svg`} alt="twopairs" />
                        </div>
                        <p className="result">8:1</p>
                    </div>
                    <div className="item">
                        <p>Каре</p>
                        <div>
                            <Picture src={`${env.mediapoint}/images/combinations/fourofakind.svg`} alt="twopairs" />
                        </div>
                        <p className="result">40:1</p>
                    </div>
                    <div className="item">
                        <p>Стрит флеш</p>
                        <div>
                            <Picture src={`${env.mediapoint}/images/combinations/streetFlash.svg`} alt="twopairs" />
                        </div>
                        <p className="result">100:1</p>
                    </div>
                    <div className="item">
                        <p>Рояль флеш</p>
                        <div>
                            <Picture src={`${env.mediapoint}/images/combinations/royalFlash.svg`} alt="twopairs" />
                        </div>
                        <p className="result">200:1</p>
                    </div>
                </div>

            </>
        )
    }
}

export default GamePayout