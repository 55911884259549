import React, { Component } from "react"

/* Constants */
import { env } from "../constants"

/* Components */
import { Picture } from "@alicorn/poker-ui"

/* utils */
import { get_unique_values } from "../constants/utils"

/* Fields */
const PLAYER_SPACES = ['9', '7', '5', '3', '1']


/* Widget uMP Player Cards */
class UMPPlayerCards extends Component {

    constructor(props) {
        super(props)
    }

    // componentDidUpdate = (prevProps) => {

    //     const { game } = this.props

    //     if (game && parseInt(game.level) > 0 && prevProps && parseInt(prevProps.level) > 0) {
    //         if (parseInt(game.level) !== parseInt(prevProps.level)) {
    //             this.setState({ game: null })
    //             const timer = setTimeout(() => {
    //                 this.setState({ game })
    //                 clearTimeout(timer)
    //             }, 1000)
    //         }
    //     }
    // }

    clearGame = () => {
        // this.setState({ game: null })
    }

    /* Card status */
    status = card => {

        const { game } = this.props

        let status = false

        if (game && game.data) {
            const index = game.data.findIndex(e => parseInt(e.id) === parseInt(card.id))
            if (index > -1) {
                if (game.data[index].status) {
                    status = true
                }
            }
        }

        return status
    }


    /* Draw card image */
    _image = card => {
        const status = this.status(card)
        let style = status ? 'ump--animated-card-image' : ''
        if (card.player) {
            style += ' player-card'
        }
        return <Picture className={style} src={`${env.mediapoint}/cards/${card.image}`} alt="Player Card" />
    }


    /* Draw flip animation box */
    _flip = card => {
        return (
            <div className={`ump--player-flip-card active`}>
                <div className="ump--player-flip-card-inner">
                    <div className="ump--player-flip-card-front">
                        <Picture src={`${env.mediapoint}/cards/closed.webp`} alt="Player Card" />
                    </div>
                    <div className="ump--player-flip-card-back">
                        {this._image(card)}
                    </div>
                </div>
            </div>
        )
    }


    /* Draw Status */
    _status = (card, key) => {
        const status = this.status(card)
        let style = ''
        if (card.player) {
            style += 'player-card'
        }

        return (
            <div className={`ump--player-game-status ${status ? 'ump--player-game-status-animation' : ''}`} key={key}>
                <div className={`ump--player-game-status-corner ${style}`} />
            </div>
        )

    }

    /* Draw Player cards */
    _player = () => {

        const { cards, isExchange, used } = this.props
        const tapScreenStatus = cards.length === 5 && !used

        const filtered_cards = get_unique_values(cards, "id")

        return (
            <div className="ump--player-box">

                <div className={`ump--player-cards ${isExchange ? 'ump--player-exchange-cards' : ''}`}>
                    {filtered_cards.map((card, index) => {
                        const cardKey = (card.uid ? card.uid : card.id)
                        return (
                            <div className={`ump--player-card  ${tapScreenStatus ? 'ump--player-card-tap' : ''}`} key={`card_${cardKey}`}>
                                {this._flip(card)}
                                {this._status(card, (cardKey + index))}
                            </div>
                        )
                    })}
                </div>

                <div className="ump--player-cards-places">
                    {PLAYER_SPACES.map((item, index) => {
                        if (!index || (index <= filtered_cards.length - 1)) {
                            return (
                                <div key={`place-${item}`} className={`ump--player-card-place ${tapScreenStatus ? 'ump--player-card-place-tap' : ''}`}></div>

                            )
                        }
                    })}
                </div>

            </div>
        )
    }


    /* Draw player game result */
    _result = () => {

        const { game } = this.props

        let result = ""
        if (game) {
            if (parseInt(game.level) === 0) {
                result = "Нет игры"
            }
            if (parseInt(game.level) > 0) {
                result = game.name
                if (game.hasAceKing) {
                    result = result + " + Туз Король"
                }
            }
        }

        if (result !== "") {
            if (result === "Нет игры") {
                return <div className={`ump--player-result ump--player-result-red`}>{result}</div>
            }
            else {
                return <div className={`ump--player-result`}>{result}</div>
            }
        }
    }


    /* Draw Player Cards */
    _playerBox = () => {
        return (
            <div className="ump--player">
                <h2>Рука игрока</h2>
                {this._player()}
                {this._result()}
            </div>
        )
    }


    render = () => {

        const { style } = this.props

        return (
            <div className={`ump--player-card-container ${style}`}>
                {this._playerBox()}
            </div>
        )
    }

}

export default UMPPlayerCards