import React, { Component, createRef } from 'react'

/* Socket IO */
import { io } from "socket.io-client"

/* JWT */
import { decodeToken } from 'react-jwt'

/* Constants */
import { images, env, sound, times } from './constants'
import { LOADING, ERROR, CHOICE, PRE_FLOP, FLOP, TURN_RIVER, WAITING, DEALING, GAMEPLAY } from './constants/status'

/* UUID */
import { v4 as uuidv4 } from 'uuid'

/* Alicorn Poker UI */
import { Error, Background, GameControl, Balance, Internet, GameInfo, Status, Timer, Notification, FloatingMessages, InactionTiming } from '@alicorn/poker-ui'

/* Widgets */
import { Preloader } from './widgets'

/* Rules */
import { list } from './constants/rules'

/* Words */
import { swearWords } from './constants/badwords'

/* REST API */
import { history } from './api/History'

import { UltimateRates, GamePayout } from './components'

import { Bet, PlayerCards, DealerCards, Buttons } from './widgets'


/* Intenet speed check url */
const imgUrl = "https://testing-cb.makaobet.com/images/dealerinfo/close.png"


/* Game quit alicorn */
import { setIframeMessageSenderSettings, useSendInitIframeMessage } from '@alicorn/iframe-message-sender'

/* Fields */
const pathname = window.location.search
const token = pathname.length > 1 ? pathname.substring(1) : ""

/* FRAME SETTINGS */
setIframeMessageSenderSettings({ parentName: 'makao-front', sourceName: 'poker', isChild: true })


/* Entry Point */
class App extends Component {


    constructor() {
        super()

        const data = decodeToken(token)

        this.state = {
            /* General */
            cached: false,
            status: LOADING,
            balance: null,
            total: 0,
            currency: (token && data && data.currency) ? data.currency : 'USD',
            identifier: (token && data && data.identifier) ? data.identifier : '1',
            lastWin: 0,
            activeChip: 3,
            ante: 0,
            blind: 0,
            trips: 0,
            betX: 0,
            last: null,
            total: 0,
            result: null,

            /* Player */
            playerCards: [],
            playerGame: null,

            /* Dealer */
            dealerInfo: null,
            dealerCards: [],
            dealerGame: null,

            stage: null,
            showButton: false,
            action: null,

            /* Game Info */
            max: data && data.max ? data.max : 5000,
            min: data && data.min ? data.min : 50,
            maxPay: data && data.maxPay ? data.maxPay : 100000,

            times: {
                choise: times.CARD_HAND_UP_TIME,
                firstGame: times.FIRST_GAME_STAGE_TIME,
                secondGame: times.SECOND_GAME_STAGE_TIME,
                purchaseTime: times.THIRD_GAME_STAGE_TIME
            },

            gameInfo: null,
            transactions: [],
            operations: [],
            histories: [],
            historyLoading: true,
            historyPage: 1,
            historyHasMore: true,

            /* Game Session */
            closed: false,
            newDevice: false,
            isDisconnected: false,
            isPaused: false,

            /* Other */
            hls: data && data.hls ? data.hls : null,
            chips: data && data.chips && Array.isArray(data.chips) ? data.chips : [],
            backColor: data && data.backColor ? data.backColor : "",
            gameImg: data && data.gameImg ? `${env.mediapoint}/images/table-images/` + data.gameImg : null,

        }

        this.notifyRef = React.createRef()

        /* Start connection */
        this.socket = io(env.endpoint, { auth: { token: `${token}`, reconnection: false, isPlayer: true } })

        /* Timing ref */
        this._balanceErrorTiming = null
        this._maxBetErrorTiming = null

        /* Backfround ref */
        this._background = createRef(null)

    }

    componentDidMount = () => {
        this.game()
        this.cache()
        this.loadHistory(1)
    }

    /* Load History */
    loadHistory = (page = 1) => {

        const { histories } = this.state

        if (page > 1) {
            this.setState({ historyPage: page })
        }

        history(token, page).then(response => {
            if (response.status === 200) {
                let list = response.data

                if (page > 1) {
                    list = [...histories, ...response.data]
                }

                if (response.data.length < 5) {
                    this.setState({ historyHasMore: false })
                }

                this.setState({ histories: list, historyLoading: false })
            }
        }).catch(() => {
            this.setState({ histories: [], historyLoading: false })
        })
    }

    /* Send Message */
    sendMessage = message => {
        // const lowerCaseText = message;
        let counter = 0
        // swearWords.forEach(item => {
        //     // if (lowerCaseText.includes(item)) {
        //     //     counter += 1
        //     // }
        // })

        if (!counter) {
            this.socket.emit("messageFromPlayer", message.message)
            if (this._floatingMessages) {
                this._floatingMessages.sendMessage(message)
            }
        } else {
            
        }
    }

    game = () => {
        /* On error */
        this.socket.on("connect_error", () => {

            this.clear()

            const timer = setTimeout(() => {
                this.setState({ status: ERROR })
                clearTimeout(timer)
            }, 1000)

            this.clearTiming()
        })

        /*  */
        this.socket.on("disconnect", () => {
            this.setState({ isDisconnected: true })
            this.clear()
            this.clearTiming()
        })

        /* On connect */
        this.socket.on("connect", () => {
            const timer = setTimeout(() => {
                this.setState({ status: null })
                clearTimeout(timer)
                this.resetTimer()
            }, 2000)
        })

        /* On New Device */
        this.socket.on("newDeviceConnection", () => {
            this.setState({ newDevice: true })
            this.socket.disconnect()
            this.clear()
            this.clearTiming()
        })

        /* Balance */
        this.socket.on("balance", data => {
            this.setState({ balance: data })
        })

        /* Get pause command from dealer monitor */
        this.socket.on("pause", () => {
            this.setState({ isPaused: true })
            this.notify('pause', 'show')
            this.clearTiming()
        })

        /* Continue Event */
        this.socket.on("continue", () => {
            this.setState({ isPaused: false })
            this.notify('pause', 'close')
        })

        /* On start */
        this.socket.on("status", data => {
            const { status, ante, action } = this.state

            if (status !== data) {
                if (data === CHOICE && status !== CHOICE) {
                    this.loadHistory(1)
                    this.clear()
                }
                if (data === PRE_FLOP || data === FLOP || data === TURN_RIVER) {
                    if (!action || action === 'check') {
                        this.setState({ showButton: true, action: null })
                    }
                }
                if (data !== CHOICE && !ante) {
                    this.setState({ status: WAITING })
                }
                else {
                    this.setState({ status: data })
                }
            }
        })

        /* Reconnection */
        this.socket.on("reconnection", data => {
            if (data) {
                this.reconnectionClear()

                if (data.reason && data.reason === "transaction") {
                    this.notify("transaction", 'show')
                }

                let showButton = false
                let action = null

                const status = data.status
                const ante = data.ante ? data.ante : 0
                const times = data.times
                const stage = data.stage ? data.stage : null

                const playerCards = data.playerCards
                const playerGame = data.playerGame


                const dealerCards = data.dealerCards
                const dealerGame = data.dealerGame

                const isPaused = data.isPaused

                setTimeout(() => {

                    this.setState({ status })

                    if (isPaused) {
                        this.notify("pause", "show")
                    }

                    if (data.autopass) {
                        this.notify("autopass", "show")
                        setTimeout(() => {
                            this.notify("autopass", "close")
                        }, 5000);
                    }

                    this.notify("pause", 'close')
                    this.notify("transaction", 'close')

                    if (ante) {

                        if (data.betX) {
                            action = data.betX
                        } else if (data.pass) {
                            action = 'pass'
                        } else if (data.check) {
                            action = 'check'
                        }

                        if ((status === PRE_FLOP || status === FLOP || status === TURN_RIVER) && !action) {
                            showButton = true
                        }

                        this.setState({
                            ante,
                            blind: ante,
                            trips: data.trips,
                            last: data.last,
                            betX: data.betX ? data.betX : 0,
                            total: data.total,
                            times,
                            isPaused,

                            showButton,
                            action,

                            gameInfo: data.gameData,

                            result: data.result,
                            transactions: data.transactions

                        })
                    }

                    this.setState({
                        stage: data.stage, dealerInfo: data.dealerData, playerGame, dealerGame, playerCards, dealerCards
                    })

                    setTimeout(() => {

                        if (this._timing && times && stage && parseInt(times[stage]) > 0) {
                            this._timing.start(times[stage], stage)
                        }
                        else {
                            setTimeout(() => {
                                if (this._timing && times && stage && parseInt(times[stage]) > 0) {
                                    this._timing.start(times[stage], stage)
                                }
                            }, 1500)
                        }

                    }, 1000)

                }, 2000)

            }
        })

        /* Get a dealer Info */
        this.socket.on("dealerData", data => {
            this.setState({ dealerInfo: data })
        })

        /* Get a dealer card */
        this.socket.on("dealer", data => {
            const { dealerCards } = this.state
            const i = dealerCards.findIndex(e => e.id === data.id)
            if (i === -1) {
                let cards = dealerCards
                cards.unshift(data)
                this.setState({ dealerCards: cards })
                // sound.play('clickuibut', 0.2)
            }
        })

        this.socket.on("dealerCards", data => {
            this.setState({ dealerCards: data })
        })

        this.socket.on("dealerGame", data => {
            this.setState({ dealerGame: data })
        })

        /* Get a Player card */
        this.socket.on("player", data => {
            const { playerCards } = this.state
            const i = playerCards.findIndex(e => e.id === data.id)
            if (i === -1) {
                let cards = playerCards
                cards.unshift(data)
                this.setState({ playerCards: cards })
                sound.play('clickuibut', 0.2)
            }
        })

        this.socket.on("playerGame", data => {
            this.setState({ playerGame: data })
        })

        this.socket.on("playerCards", data => {
            this.setState({ playerCards: data })
        })

        this.socket.on("gameData", data => {
            this.setState({
                playerCards: data.playerCards,
                playerGame: data.playerGame
            })
        })

        /* Timer Stage */
        this.socket.on("stage", data => {
            const { type, times } = data

            this.setState({ stage: type, times: times })

            if (this._timing && times && parseInt(times[type]) > 0 && type) {
                this._timing.start(times[type], type)
            }

        })

        /* Get a game status after inaction  */
        this.socket.on("actions", data => {
            const { check, pass } = data
            if (check) {
                this.setAction('check')
            }
            if (pass) {
                this.setAction('pass')
                this.notify('autopass', 'show')
                sound.play('autopass')
                setTimeout(() => { this.notify('autopass', 'close') }, 6000)
            }
        })

        /* Result */
        this.socket.on("result", result => {
            this.setState({ result })
            if (result && result.result === "win") {
                sound.play('win', 0.5)
                this.setState({ lastWin: result.sum })
            }
        })

        /* Message */
        this.socket.on("message", data => {
            if (this._floatingMessages) {
                this._floatingMessages.sendMessage(data)
            }
            if (this._background && this._background.current) {
                this._background.current.addMessage(data)
            }
        })

        /* Get Game Info */
        this.socket.on("gameInfo", data => {
            this.setState({ gameInfo: data })
        })

        /* Get transactions list */
        this.socket.on("transaction", data => {
            if (data.id) {
                const { transactions, operations } = this.state
                let list = [data, ...transactions]
                let operationsList = [data, ...operations]
                this.setState({ transactions: list, operations: operationsList })

                setTimeout(() => {
                    let operationsList = this.state.operations
                    operationsList = operationsList.filter(item => item.id !== data.id)
                    this.setState({ operations: operationsList })
                }, 3500)

            }
        })

        this.socket.on("editTransaction", data => {
            const { transactions } = this.state
            let list = transactions
            const index = list.findIndex(e => e.number === data.number)
            if (index > -1) {
                list[index].status = data.status
                this.setState({ transactions: list })
            }
        })

    }

    /* Clear timing to remove sound */
    clearTiming = () => {
        if (this._timing) {
            this._timing.clear()
        }
        setTimeout(() => {
            if (this._timing) {
                this._timing.clear()
            }
        }, 1500)
    }

    reconnectionClear = () => {

    }

    /* Show and close notifications */
    notify = (type, action, data = null) => {

        const notifyData = { type, action, data }

        if (this._notify) {
            this.notifyAction(notifyData)
        }
        else {
            setTimeout(() => {
                if (this._notify) {
                    this.notifyAction(notifyData)
                }
            }, 2000)
        }

    }

    /* Do notify actions after notify component check */
    notifyAction = (notifyData) => {

        const { type, action, data = null } = notifyData
        if (action === "show") {
            this._notify.show(type)
        }
        else if (action === 'close') {
            this._notify.close(type)
        }
        else if (action === 'refund') {
            if (data.total) {
                const title = data.type === 'refund' ? 'Возврат средств' : "Пополнение суммы"
                const uid = uuidv4()
                this._notify.refund({ title, total: data.total, uid })
            }
        }

    }

    /* Check internet speed */
    checkInternetSpeed = (speed) => {
        if (parseInt(speed) < 5) {
            this.notify('internet', 'show')
        }
        else {
            this.notify('internet', 'close')
        }
    }

    /* Cache images */
    cache = async () => {

        const promises = images.data.map(uri => {

            return new Promise(function (resolve, reject) {
                const img = new Image()
                img.src = uri
                img.onload = resolve()
                img.onerror = reject()
            })

        })

        await Promise.all(promises)

        this.setState({ cached: true })
    }

    /* Reser top timing */
    resetTimer = () => {
        if (this._inaction) {
            this._inaction.reset()
        }
    }

    /* Disconnect user when inaction timer equal to 0 */
    setInactionState = () => {
        this.setState({ closed: true })
        this.socket.disconnect()
        this.clear()
    }


    /* Toggle Balance Error */
    toggleBalanceError = () => {

        if (!this._balanceErrorTiming) {
            this.notify('balanceError', 'show')
        }

        if (this._balanceErrorTiming) {
            clearTimeout(this._balanceErrorTiming)
            this._balanceErrorTiming = null
        }

        this._balanceErrorTiming = setTimeout(() => {
            this.notify('balanceError', 'close')
            clearTimeout(this._balanceErrorTiming)
            this._balanceErrorTiming = null
        }, 2000)

    }

    /* Toggle Balance Error */
    toggleMaxBetError = () => {

        if (!this._maxBetErrorTiming) {
            this.notify('maxBetError', 'show')
        }

        if (this._maxBetErrorTiming) {
            clearTimeout(this._maxBetErrorTiming)
            this._maxBetErrorTiming = null
        }

        this._maxBetErrorTiming = setTimeout(() => {
            this.notify('maxBetError', 'close')
            clearTimeout(this._maxBetErrorTiming)
            this._maxBetErrorTiming = null
        }, 2000)

    }


    /* Set ante value */
    setStake = (data) => {
        const { ante, trips } = data

        let stakeData = { ante, trips }
        const total = ante * 2 + trips
        this.setState({ ante: ante, blind: ante, trips: trips, last: stakeData, total: total, lastWin: 0 })
        this.socket.emit("stake", stakeData)

        sound.play('sound')
        this.resetTimer()
    }

    /* Set action from player to server */
    setAction = (data) => {
        const { balance, ante, betX, total, status } = this.state

        this.resetTimer()

        if (!betX) {

            let success = false

            if (data === 'pass' || data === 'check') {
                this.socket.emit("playerAction", data)
                this.setState({ action: data })
                success = true
            } else {
                if ((ante * parseInt(data)) > balance) {
                    this.toggleBalanceError()
                } else {
                    this.socket.emit("playerAction", data)
                    this.setState({ action: data, betX: parseInt(data), total: total + (ante * parseInt(data)) })
                    success = true
                }
            }

            if (success) {
                setTimeout(() => {
                    this.setState({ showButton: false })
                }, 1500);
                setTimeout(() => {
                    this.setState({
                        status: status === TURN_RIVER ? GAMEPLAY : DEALING
                    })
                }, 2000)
            }
        }

    }


    /* Clear all variables */
    clear = () => {
        this.setState({
            ante: 0,
            blind: 0,
            trips: 0,
            betX: 0,
            total: 0,
            result: null,

            /* Player */
            playerCards: [],
            playerGame: null,

            /* Dealer */
            dealerCards: [],
            dealerGame: null,

            stage: null,
            showButton: false,
            action: null,

            gameInfo: null,
            transactions: [],
        })
    }

    /* Dealer Cards */
    _dealer_cards = (text = '') => {

        const { dealerCards, dealerGame, action, betX } = this.state

        const showDealerCards = action === 'pass' || betX

        let style = ''

        if (text === 'nextgame' && dealerCards.length === 0) {
            style = 'fullHidden'
        }

        return (
            <DealerCards
                cards={dealerCards}
                game={dealerGame}
                showDealerCards={showDealerCards}
                style={style}
            />
        )

    }

    /* Player Cards */
    _player_cards = (text = '') => {

        const { playerCards, playerGame } = this.state

        let style = ''

        if (text === 'nextgame' && playerCards.length === 0) {
            style = 'fullHidden'
        }

        return (
            <PlayerCards
                ref={ref => this._playerCards = ref}
                cards={playerCards}
                game={playerGame}
                style={style}
            />
        )

    }

    /* Draw Result */
    _result = () => {

        const { result, currency } = this.state

        if (result) {
            return <Status status="result" data={result} currency={currency} isPurchase={false} />
        }
    }

    box = () => {

        const { ante, blind, trips, betX, last, showButton, action, result, activeChip, balance, times, status, currency, max, min, chips, backColor } = this.state

        const gameplay = status === PRE_FLOP || status === FLOP || status === TURN_RIVER || status === DEALING || status === GAMEPLAY

        const lose = result && result.result === 'lose'

        const boxStyle = ''
        const betAnimatedStyle = ''

        if (gameplay) {
            return (
                <div className='play'>

                    {this._result()}

                    <div className="card-box">

                        {this._dealer_cards()}

                        <div className="card-box-bottom">
                            <div className="player-game">
                                {this._player_cards()}
                            </div>
                        </div>

                    </div>

                    {
                        showButton &&
                            !result ?
                            <Buttons
                                status={status}
                                action={action}
                                setAction={data => this.setAction(data)}
                            />
                            : null
                    }


                    <UltimateRates
                        ante={ante}
                        blind={blind}
                        trips={trips}
                        betX={betX}
                        action={action}
                        result={result}
                        currency={currency}
                        chips={chips}
                        boxStyle={boxStyle}
                        betAnimatedStyle={betAnimatedStyle}
                        lose={lose}
                        backColor={backColor}
                        add={() => { }}
                        gameplay={true}
                    />
                </div>
            )
        }

        if (status === CHOICE) {
            return (
                <Bet
                    ante={ante}
                    blind={blind}
                    trips={trips}
                    last={last}
                    active={activeChip}
                    setActiveChip={data => this.setState({ activeChip: data })}
                    setStake={data => this.setStake(data)}
                    balance={balance}
                    currency={currency}
                    toggleBalanceError={() => this.toggleBalanceError()}
                    toggleMaxBetError={() => this.toggleMaxBetError()}
                    times={times}
                    boxStyle={boxStyle}
                    isChoise={status === CHOICE}
                    max={max}
                    min={min}
                    chips={chips}
                    backColor={backColor}
                />
            )
        }

        return (
            <div className='play'>

                <div className="card-box">

                    {this._dealer_cards('nextgame')}

                    <div className="card-box-bottom">
                        <div className="player-game">
                            {this._player_cards('nextgame')}
                        </div>
                    </div>

                </div>

                <UltimateRates
                    ante={ante}
                    blind={blind}
                    trips={trips}
                    currency={currency}
                    chips={chips}
                    boxStyle={boxStyle}
                    backColor={backColor}
                    add={() => { }}
                    gameplay={true}
                    disabled={true}
                />
            </div>
        )
    }



    render = () => {

        const { status, cached, dealerInfo, currency, total, balance, lastWin, action, ante, transactions, gameInfo, operations, histories, historyPage, historyHasMore, closed, newDevice, max, min, maxPay, isDisconnected, isPaused, identifier, hls, backColor, gameImg } = this.state

        const nextGame = status === WAITING

        const style = (nextGame || newDevice || closed || isDisconnected) ? "hidden" : action ? "blured" : ""

        const gamePayout = <GamePayout currency={currency} max={max} min={min} />

        let content = (
            <Background gameType="ultimate" ref={this._background} url={hls} currency={currency} maxPay={maxPay} status={status} dealer={dealerInfo} sendMessage={message => this.sendMessage(message)} info={gameInfo} transactions={transactions} histories={histories} loadMore={() => this.loadHistory(historyPage + 1)} historyHasMore={historyHasMore} identifier={identifier} rules={list} gamePayout={gamePayout} videoBackImg={gameImg} textileColor={backColor}>

                <Timer key="timing" ref={ref => this._timing = ref} style={style} isPaused={isPaused} ante={ante} />
                <InactionTiming time={240} ref={ref => this._inaction = ref} status={status} isPaused={isPaused} setInactionState={() => this.setInactionState()} />
                <HookWrapper />
                <GameInfo currency={currency} info={gameInfo} operations={operations} />
                <GameControl title={`Ultimate Texas Holdem`} maxPay={maxPay} max={max} min={min} currency={currency} info={gameInfo} data={dealerInfo} gamePayout={gamePayout} onQuitGame={() => this.quitGame()} />
                <Balance balance={balance} total={total} lastWin={lastWin} currency={currency} />
                <FloatingMessages ref={ref => this._floatingMessages = ref} />

                {this.box()}

                <Notification nextGame={nextGame} ref={ref => this._notify = ref} currency={currency} max={max} />

                <Internet url={imgUrl} setInternetSpeed={data => this.checkInternetSpeed(data)} />

            </Background>
        )

        if (status === ERROR || newDevice || closed || isDisconnected) {
            content = <Error newDevice={newDevice} closed={closed} isDisconnected={isDisconnected} />
        }

        if (status === LOADING || !cached) {
            content = <Preloader url={gameImg} />
        }

        return content
    }

}

export default App

function HookWrapper() {
    useSendInitIframeMessage()
    return null
}