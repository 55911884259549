import * as env from './env'

const currencies = [
    {
        name: 'Dollar',
        code: 'USD',
        symbol: '$',
        isAfter: 0,
        chips: [1, 5, 25, 100, 500, 1000],
    },
    {
        name: 'Euro',
        code: 'EUR',
        symbol: '€',
        isAfter: 0,
        chips: [5, 25, 50, 100, 250, 1000],
    },
    {
        name: 'Pound sterling',
        code: 'GBP',
        symbol: '£',
        isAfter: 0,
        chips: [5, 25, 50, 100, 250, 1000],
    },
    {
        name: 'Ruble',
        code: 'RUB',
        symbol: '₽',
        isAfter: 1,
        chips: [50, 250, 500, 1000, 2500, 10000],
    },
    {
        name: 'Japanese yen',
        code: 'JPY',
        symbol: '¥',
        isAfter: 1,
        chips: [50, 250, 500, 1000, 2500, 10000],
    },
    {
        name: 'Swiss frank',
        code: 'CAD',
        symbol: '₣',
        isAfter: 1,
        chips: [5, 25, 50, 100, 250, 1000],
    },
    {
        name: 'South Korean Won',
        code: 'KRW',
        symbol: '₩',
        isAfter: 1,
        chips: [5000, 25000, 50000, 100000, 250000, 1000000],
    },
    {
        name: 'Kazakh tenge',
        code: 'KZT',
        symbol: '₸',
        isAfter: 1,
        chips: [200, 1000, 5000, 25000, 50000, 100000],
    },
    {
        name: 'Ukrainian hryvnia',
        code: 'UAH',
        symbol: '₴',
        isAfter: 1,
        chips: [500, 1000, 2000, 5000, 10000, 20000]
    },
    {
        name: 'Uzbek sum',
        code: 'UZS',
        symbol: 'сўм',
        isAfter: 1,
        chips: [100000, 200000, 500000, 1000000, 5000000, 10000000]
    },
    {
        name: 'Kyrgyz som',
        code: 'KGS',
        symbol: 'с.',
        isAfter: 1,
        chips: [50, 250, 500, 1000, 2500, 10000],
    },
]


/* Balance convertor */
const convertor = (number, currency, symbol = " ") => {

    let space = '\xa0'
    /* Check the price for the void */
    if (number === null || number === undefined)
        return ""

    const balance = parseFloat(number)

    let final = Number.isInteger(balance) ? balance.toFixed(0) : balance.toFixed(2)

    if (currency.toLowerCase() === 'uzs') {
        final = counter(final)
    }

    const index = currencies.findIndex(c => c.code.toLowerCase() === currency.toLowerCase())

    let beforeSymbol = ''
    let afterSymbol = ''

    if (index > -1) {
        if (currencies[index].isAfter === 1) {
            afterSymbol = space + currencies[index].symbol
        }
        if (currencies[index].isAfter === 0) {
            beforeSymbol = currencies[index].symbol + space
        }
    }
    else {
        beforeSymbol = "$" + space
    }

    /* Return price with spaces and currency */
    return beforeSymbol + final.toString().replace(/\B(?=(\d{3})+(?!\d))/g, symbol) + afterSymbol
}


/* Check for empty */
const isEmpty = data => {
    if (data === null || data === undefined) {
        return false
    }

    return true
}


/* Counter */
const counter = (value, fixed = 1) => {

    let count = value

    if (parseInt(value) >= 1000) {
        if (parseInt(value) % 1000 === 0) {
            const calculate = Math.round(parseInt(value) / 1000)
            count = `${calculate}K`
        }
        else if (parseInt(value) % 100 === 0) {
            const calculate = (parseInt(value) / 1000).toFixed(fixed)
            count = `${calculate}K`
        }
    }

    if (parseInt(value) >= 1000000) {
        const calculate = (parseInt(value) / 1000000).toFixed(fixed)
        count = `${calculate}M`
    }

    return count
}

/* Divide number */
const numberWithSpaces = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
}

const fontSize = (value, defaultValue = 15) => {
    const ante_length = (String(counter(value))).length

    const size = defaultValue - (ante_length > 3 ? ante_length : 0) + 1

    return size
}


const getChips = (currency = 'USD', chips) => {

    const index = currencies.findIndex(c => c.code.toLowerCase() === currency.toLowerCase())

    if (index > -1) {

        const list = chips ? chips : currencies[index].chips

        let data = []
        list.forEach((chip, index) => {
            data.push({ key: index + 1, value: parseInt(chip), image: `${env.mediapoint}/chips/${index + 1}.webp`, image3d: `${env.mediapoint}/chips/${index + 1}_3d.webp` })
        })

        return data
    }
    
    return []
    
}

const get_unique_values = (arr, prop) => {
    return arr.filter((obj, index, self) =>
        index === self.findIndex((o) =>
            o[prop] === obj[prop]
        )
    )
}


export { isEmpty, convertor, counter, numberWithSpaces, fontSize, getChips, get_unique_values}