const LOADING = "loading"
const ERROR = "error"
const WAITING = "waiting"
const CHOICE = "choise"
const DEALING = "dealing"
const GAMEPLAY = "gameplay"
const PRE_FLOP = 'preflop'
const FLOP = 'flop'
const TURN_RIVER = 'turnriver'
const BUSY = "busy"

export { LOADING, ERROR, WAITING, CHOICE, PRE_FLOP, FLOP, TURN_RIVER, BUSY, DEALING, GAMEPLAY } 